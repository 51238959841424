<div class="row">
    <div class="col-md-12 text-center">
        <h1>ASOCIADOS</h1>
        <div class="row">
            <div class="col-md-4 col-12 mb-1">
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="Buscar" aria-describedby="button-addon2"
                        style="border-radius:20px 0px 0px 20px;" [(ngModel)]="search"  (change)="searchPartners()"/>
                    <div class="input-group-append" id="button-addon2">
                        <button type="button" class="btn btn-icon btn-outline-primary" style="border-radius:0px 20px 20px 0px;"
                            rippleEffect (change)="searchPartners()">
                            <span [data-feather]="'search'"></span></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-4 mt-5" *ngFor="let x of partners">
        <div class="card">
            <div class="row justify-content-md-center mt-3">
                <div class="col-md-4 text-center">
                    <div class="">
                        <img class="img-circP" [src]="url+x.logo" alt="banner" >
                    </div>
                </div>
            </div>
            <!-- <img class="card-img-top" [src]="url + x.logo" alt="banner"> -->
            <div class="card-body">
                <!-- <h4 class="card-title">{{x.name}} </h4> -->
                <a *ngIf="x.url_inmo != null" class="text-primary" (click)="tarjetaDigital(x.url_inmo)">
                    <h3 class="text-center text-dark mt-1">{{x.name}}</h3>
                </a>
                <a *ngIf="x.url_inmo == null">
                    <h3 class="text-center text-dark mt-1">{{x.name}}</h3>
                </a>
                <p *ngIf="x.position">Localia: <strong>{{x.position}}</strong> </p>
                <p class="card-text mt-1 ">
                    Ubicación: {{x.street+', #'+x.noExt+', '+x.province+', '+x.state}}
                    <br>
                    Teléfono: {{x.phone | slice:0:3
                    }}-{{x.phone | slice:3:6
                    }}-{{x.phone |
                    slice:6:10 }}
                    <br>
                    Email: {{x.email}}
                </p>
            </div>

        </div>

    </div>
</div>
import { CoreMenu } from '@core/types'

export const menu: CoreMenu[] = [
  {
    id: 'my-page',
    title: 'Mi pagina',
    type: 'collapsible',
    icon: 'globe',
    children: [
      {
        id: 'slider',
        title: 'Slider',
        type: 'item',
        icon: 'circle',
        url: 'my-page/slider'
      },
      {
        id: 'us',
        title: 'Nosotros',
        type: 'item',
        icon: 'circle',
        url: 'my-page/us'
      },
      {
        id: 'association',
        title: 'Asociación',
        type: 'item',
        icon: 'circle',
        url: 'my-page/association'
      },
      {
        id: 'us',
        title: 'Categorias',
        type: 'item',
        icon: 'circle',
        url: 'my-page/categories'
      },
    ]
  },
  {
    id: 'benefits',
    title: 'Beneficios',
    type: 'item',
    icon: 'thumbs-up',
    url: '/benefits'
  },
  {
    id: 'partners',
    title: 'Asociados',
    type: 'item',
    icon: 'users',
    url: '/partners'
  },
  {
    id: 'events',
    title: 'Eventos',
    type: 'item',
    icon: 'award',
    url: '/events'
  },
  {
    id: 'sponsors',
    title: 'Patrocinadores',
    type: 'item',
    icon: 'award',
    url: '/sponsors'
  },
  {
    id: 'affiliates',
    title: 'Afiliados',
    type: 'item',
    icon: 'award',
    url: '/affiliates'
  },
]

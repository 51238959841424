import { Role } from './role';
import { Association } from '../../models/association.model';

export class User {
  id: number;
  email: string;
  password: string;
  role?: Role;
  token?: string;
  association: Association;
}

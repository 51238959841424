import { Component, OnInit } from '@angular/core';
import { LandingService } from 'app/main/pages/landing/landing.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-pagina-adicional',
  templateUrl: './pagina-adicional.component.html',
  styleUrls: ['./pagina-adicional.component.scss']
})
export class PaginaAdicionalComponent implements OnInit {
  usText: string;
  public quillEditorContent = `
  `;
  domainPage: string;
  banderaDesplegar = false;
  existeImagen = false;
  imagen;

  constructor(private _LandingService: LandingService) { }

  ngOnInit(): void {
    var host = window.location.host;
    const domain = host.split('.')[1];
    let data;
    if (domain == 'ampi') {
      this.domainPage = host.split('.')[0];

    } else {
      this.domainPage = window.location.host;
    }
    console.log("este es el dominio", this.domainPage);

    this._LandingService.getServer('usAssociation', this.domainPage).then(
      (resp: any) => {
        this.usText = resp['us'];
        if (resp.about_us_image != null) {
          this.existeImagen = true;
          this.imagen = environment.URL_AWS + resp.about_us_image;
          console.log(this.imagen);
        }

        setTimeout(() => {
          var cols = document.getElementsByClassName('ql-align-center');
          for (let i = 0; i < cols.length; i++) {
            cols[i].setAttribute('style', 'text-align: center');
          }
          var cols = document.getElementsByClassName('ql-align-left');
          for (let i = 0; i < cols.length; i++) {
            cols[i].setAttribute('style', 'text-align: left');
          }
          var cols = document.getElementsByClassName('ql-align-right');
          for (let i = 0; i < cols.length; i++) {
            cols[i].setAttribute('style', 'text-align: right');
          }

          var cols = document.getElementsByClassName('ql-editor');
          for (let i = 0; i < cols.length; i++) {
            cols[i].setAttribute('style', 'color: with');
          }

        }, 500);

      }, (err: any) => {
        console.log("ERROR ", err);
      }
    );
  }

}

import { Component, OnInit } from '@angular/core';
import { LandingService } from 'app/main/pages/landing/landing.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-socios',
  templateUrl: './socios.component.html',
  styleUrls: ['./socios.component.scss']
})
export class SociosComponent implements OnInit {
  url = environment.URL_AWS;
  partners:any = [];
  partnersOrigin: any[];
  search: string;
  bandShow: boolean
  domainPage: string;

  constructor(private _LandingService: LandingService) { }

  ngOnInit(): void {
    var host = window.location.host;
    const domain = host.split('.')[1];
    let data;
    if (domain == 'ampi') {
      this.domainPage = host.split('.')[0];

    } else {
      this.domainPage = window.location.host;
    }
    // this.domainPage = "sanluispotosi";
    console.log("este es el dominio", this.domainPage);
    this._LandingService.getServer('partners', this.domainPage).then(
      (resp:any) => {
        this.partners = resp;
        this.partnersOrigin = resp;
        console.log(this.partners);
      }, (err: any) => {
        console.log("ERROR ", err);
      }
    );
  }

  tarjetaDigital(page: string): void {

    if (page) {
      window.open(page, "_blank");
    }
  }

  searchPartners() {
    this.bandShow = false;
    this.partners = this.partnersOrigin;

    this.partners = this.partners.filter(x => x.name.toUpperCase().includes(this.search.toUpperCase()));

    console.log("partners", this.partners);

    setTimeout(() => {
      this.bandShow = true;
    }, 100);

  }

}

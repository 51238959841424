import { Component, OnInit } from '@angular/core';
import { LandingService } from 'app/main/pages/landing/landing.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-beneficios',
  templateUrl: './beneficios.component.html',
  styleUrls: ['./beneficios.component.scss']
})
export class BeneficiosComponent implements OnInit {
  domainPage: string;
  Benefits:any;
  url = environment.URL_AWS;
  categorias;
  beneficios;
  constructor(private _LandingService: LandingService) { }

  async ngOnInit() {
    var host = window.location.host;
    const domain = host.split('.')[1];
    let data;
    if (domain == 'ampi') {
      this.domainPage = host.split('.')[0];

    } else {
      this.domainPage = window.location.host;
    }
    // this.domainPage = "sanluispotosi";
    console.log("este es el dominio", this.domainPage);
    this.categorias = await this._LandingService.getServer('categories', this.domainPage);
    console.log("categorias", this.categorias);
    this._LandingService.getServer('benefits', this.domainPage).then(
      (resp) => {
        this.Benefits = resp;
        this.beneficios = this.Benefits
        this.Benefits[0].link = true;
        console.log(this.Benefits)
      }, (err: any) => {
        console.log("ERROR ", err);
      }
    );
  }

  onChange($event){
    console.log($event)
    if($event != 0){
      this.beneficios = this.Benefits.filter((objeto) => objeto.category_id == $event);
      console.log(this.beneficios,this.Benefits);
    }
    else{
      this.beneficios = this.Benefits;
    }
  }

}

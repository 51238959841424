import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import 'hammerjs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr'; // For auth after login toast

import { CoreModule } from '@core/core.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components';

import { coreConfig } from 'app/app-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { SampleModule } from 'app/main/sample/sample.module';
import { NavbarComponent } from './layout/components/navbar/navbar.component';
import { BeneficiosComponent } from './pages/beneficios/beneficios.component';
import { SociosComponent } from './pages/socios/socios.component';
import { EventosComponent } from './pages/eventos/eventos.component';
import { PaginaAdicionalComponent } from './pages/pagina-adicional/pagina-adicional.component';
import { SponsorsComponent } from './admin/sponsors/sponsors.component';
import { AffiliatesComponent } from './admin/affiliates/affiliates.component';
import { StoreAffiliatesComponent } from './admin/affiliates/store-affiliates/store-affiliates.component';
import { StoreSponsorsComponent } from './admin/sponsors/store-sponsors/store-sponsors.component';
// import { CategoriesComponent } from './admin/categories/list-categories/categories.component';

import { ShareButtonModule } from 'ngx-sharebuttons/button';



const appRoutes: Routes = [
  // {
  //   path: 'pages',
  //   loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
  // },
  {
    path: 'login',
    loadChildren: () => import('./main/pages/authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  {
    path: '',
    loadChildren: () => import('./main/pages/landing/landing.module').then(m => m.LandingModule)
  },
  {
    path: 'beneficios',
    component: BeneficiosComponent
  },
  {
    path: 'socios',
    component: SociosComponent
  },
  {
    path: 'eventos',
    component: EventosComponent
  },
  {
    path: 'pagina-adicional',
    component: PaginaAdicionalComponent
  },
  {
    path: 'benefits',
    loadChildren: () => import('./admin/benefits/benefits.module').then(m => m.BenefitsModule)
  },
  {
    path: 'sponsors',
    loadChildren: () => import('./admin/sponsors/sponsors.module').then(m => m.SponsorsModule)
  },
  {
    path: 'affiliates',
    loadChildren: () => import('./admin/affiliates/affiliates.module').then(m => m.AffiliatesModule)
  },
  {
    path: 'partners',
    loadChildren: () => import('./admin/partners/partners.module').then(m => m.PartnersModule)
  },
  {
    path: 'events',
    loadChildren: () => import('./admin/events/events.module').then(m => m.EventsModule)
  },
  {
    path: 'my-page',
    loadChildren: () => import('./admin/my-page/my-page.module').then(m => m.MyPageModule)
  },
  {
    path: '**',
    redirectTo: '/pages/miscellaneous/error' //Error 404 - Page not found
  }
];

@NgModule({ 
  declarations: [AppComponent, BeneficiosComponent, SociosComponent, EventosComponent, PaginaAdicionalComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    
    HttpClientModule,
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled', // Add options right here
      relativeLinkResolution: 'legacy',
    }),
    TranslateModule.forRoot(),

    //NgBootstrap
    NgbModule,
    ToastrModule.forRoot(),

    // Core modules
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,

    // App modules
    LayoutModule,
    SampleModule
  ],
  providers: [
    NavbarComponent
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }

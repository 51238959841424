<!-- Vertical Menu -->
<ng-container *ngIf="menuType === 'vertical-menu'">
  <vertical-menu *ngIf="currentUser != null"></vertical-menu>
</ng-container>
<!--/ Vertical Menu -->

<!-- Horizontal Menu -->
<ng-container *ngIf="menuType === 'horizontal-menu'">
  <horizontal-menu></horizontal-menu>
</ng-container>
<!--/ Horizontal Menu -->
<div class="row">
    <div class="col-md-2 text-center">
        <a class="btn btn-primary" href="/">Atras</a>
    </div>
    <div class="col-md-8 text-center">
        <h1>BENEFICIOS</h1>
    </div>
   <div class="col-md-4">
    
   </div>
    <div class="col-md-4 text-center">
        <div class="form-group">
            <label for="">Categorias</label>
            <select class="custom-select" name="category_id" id="category_id" ngModel (ngModelChange)="onChange($event)">
                <option value="0" [selected]>Todos </option>
                <option *ngFor="let x of categorias" value="{{x.id}}">{{x.name}} </option>
            </select>
        </div>
    </div>
    <div class="col-md-4">
    
    </div>
    <div *ngIf="beneficios != []" class="row">
        <div class="col-md-3" *ngFor="let x of beneficios">
            <div class="card">
                <img class="card-img-top" [src]="url + x.image" alt="banner">
                <div class="card-body text-center">
                    <h4 class="card-title">{{x.title}} </h4>
                    <h6 *ngIf="x.category_name">Categoria: {{x.category_name}} </h6>
                    <p class="card-text">{{x.description}} </p>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="beneficios == []">
        <p>No se encuentran beneficios cargados con esta informacion...</p>
    </div>

</div>